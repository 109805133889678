<template>
  <autocomplete
    ref="autocomplete"
    class="c-facet__search-bar-autocomplete"
    :search="search"
    :placeholder="model.placeholderText"
    :aria-label="model.placeholderText"
    :get-result-value="getResultValue"
    @submit="submitSearchResult"
  >
    <template
      #default="{
        rootProps,
        inputProps,
        inputListeners,
        resultListProps,
        resultListListeners,
        results,
        resultProps
      }"
    >
      <div v-bind="rootProps">
        <div class="c-facet__search-bar-input-tags">
          <div class="c-facet__search-bar-input-field-wrap u-flex u-flex-align-center">
            <input
              v-if="placeholder.length"
              ref="input"
              v-bind="inputProps"
              class="c-facet__search-bar-input-field u-ml--xs u-mr--xs"
              type="text"
              :class="[
                'autocomplete-input',
                { 'autocomplete-input-focused': focused,
                  'has-placeholder': placeholder.length
                },
              ]"
              :placeholder="placeholder"
              v-on="inputListeners"
              @focus="handleFocus"
              @blur="handleBlur"
            >
            <input
              v-else
              ref="input"
              v-bind="inputProps"
              class="c-facet__search-bar-input-field u-ml--xs u-mr--xs"
              type="text"
              :class="[
                'autocomplete-input',
                { 'autocomplete-input-focused': focused
                },
              ]"
              v-on="inputListeners"
              @focus="handleFocus"
              @blur="handleBlur"
            >
            <Loader
              v-if="isLoading"
              class="u-mr--xxs"
              color="medium-gray"
              size="small"
            />
            <button
              v-else
              class="c-button--no-styles c-button--icon c-button--icon-round c-button--icon-x-small c-button--icon-white u-flex-align-center  u-mr--xxs"
            >
              <Icon
                class="u-flex-shrink-0 u-ml--a u-mr--a"
                :icon="iconSearchBold"
                :size="16"
                fill="dark-gray"
              />
            </button>
          </div>
          <div
            v-if="$mq != 'desktop' && !hideTags && (store.state.tags.length || store.state.searchTerms.length)"
            class="c-facet__search-bar-input-mobile-tags u-mt--s"
          >
            <div class="c-facet__search-bar-input-mobile-tag-scroller u-flex ">
              <TagItem
                v-for="(tag) in store.state.tags"
                :key="tag.uid"
                :model="tag"
                class="u-ml--xs"
                @removeTag="removeTag"
              />

              <SearchTermItem
                v-for="(term) in store.state.searchTerms"
                :key="term"
                :model="term"
                class="u-ml--xs"
                @removeTerm="removeSearchTerm"
              />
            </div>
          </div>
        </div>
        <div
          class="c-facet__search-bar-autocomplete-list u-bare-list u-text-align--left"
          :style="{}"
          v-bind="resultListProps"
          v-on="resultListListeners"
        >
          <span
            v-if="results.length"
            class="c-facet__search-bar-autocomplete-list-arrow"
          />
          <span
            v-if="results.length && getTags(results).length"
            class="c-facet__search-bar-autocomplete-item-title is-first-item u-flex u-font-size-small u-text-uppercase u-font-bold u-text-nowrap"
          >
            {{ model.tagsTitle }} ({{ getTags(results).length }})
          </span>
          <div
            ref="scrollableContainer"
            class="c-facet__search-bar-facets-container"
          >
            <div
              v-for="result in getTags(results)"
              :key="resultProps[result.index].uid"
              v-bind="resultProps[result.index]"
              class="c-facet__search-bar-autocomplete-item u-font-size-large"
              v-html="result.hightLightedName"
            />
          </div>
          <span
            v-if="results.length && getFacets(results).length"
            class="c-facet__search-bar-autocomplete-item-title u-flex u-font-size-small u-text-uppercase u-font-bold u-text-nowrap"
            :class="{
              'is-first-item': !getTags(results).length,
              'u-mt--xs': getTags(results).length
            }"
          >
            {{ model.facetTitle }}
          </span>
          <div
            v-for="result in getFacets(results)"
            :key="resultProps[result.index].name"
            v-bind="resultProps[result.index]"
            class="c-facet__search-bar-autocomplete-item u-font-size-large"
            v-html="result.name"
          />
        </div>
      </div>
    </template>
  </autocomplete>
</template>

<script>
import iconSearchBold from '@ds/svg/icons/bold/search-alternate-bold.svg'
import Autocomplete from '@trevoreyre/autocomplete-vue'

import webCoreApi from '@/CVI/WebCore/lib/api'
import api from '@/CVI/Facet/lib/api'
import store from '@/CVI/Facet/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import TagItem from '@/CVI/Facet/components/TagItem.vue'
import SearchTermItem from '@/CVI/Facet/components/SearchTermItem.vue'
import tracking from '@/CVI/Facet/lib/tracking'
import pageRefresh from '@/CVI/Facet/lib/page-refresh'

let cancelToken

export default {
  components: {
    Autocomplete,
    Icon,
    Loader,
    SearchTermItem,
    TagItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    focusOnShowUp: {
      type: Boolean,
      default: false
    },
    hideTags: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconSearchBold,
      value: '',
      store,
      cancelToken: null,
      results: [],
      filteredResults: [],
      isLoading: false,
      focused: false
    }
  },
  mounted() {
    if (this.focusOnShowUp) {
      this.focusInput()
    }
  },
  methods: {
    async search(query) {
      if (query.length < 2) { return [] }
      this.isLoading = true

      const { tagUids } = this.store.getters

      if (cancelToken) {
        cancelToken.cancel()
      }

      cancelToken = webCoreApi.createCancelToken()

      const {
        data: { facets, tags }
      } = await api.search.get({ query, tagUids, cancelToken })
      const { tags: currentTags } = this.store.state

      const tagsWithoutSelectedTags = tags.filter(({ uid }) => {
        const alreadySelected = currentTags.find(currentTag => currentTag.uid == uid)
        return !alreadySelected
      })

      const sortedTags = this.sortTags(query, tagsWithoutSelectedTags)

      const results = [...sortedTags, ...facets].map((item, index) => {
        return {
          ...item,
          index
        }
      })
      this.filteredResults = [...results]

      this.isLoading = false

      return results
    },
    sortTags(query, tags) {
      const accentsRegexp = /[\u0300-\u036f]/g
      const normalizedQuery = query.toLowerCase().normalize('NFD').replace(accentsRegexp, '')

      const tagsWithHightlightedName = tags.map((tag) => {
        const normalizedTagName = tag.name.toLowerCase().normalize('NFD').replace(accentsRegexp, '')
        let startIndex = normalizedTagName.indexOf(normalizedQuery)
        if (startIndex > -1) {
          const hightLightedName = tag.name.split('').map((char, index) => {
            if (index >= startIndex && index <= startIndex + (query.length - 1)) {
              return `<b>${char}</b>`
            }
            return char
          }).join('')

          if (tag.name.length == query.length) {
            startIndex -= 100
          }
          tag.hightLightedName = hightLightedName
          tag.startIndex = startIndex
        }
        return { ...tag }
      })

      return tagsWithHightlightedName.sort((a, b) => {
        if (a.startIndex < b.startIndex) return -1
        if (a.startIndex > b.startIndex) return 1
        return 0
      })
    },
    handleFocus() {
      this.focused = true
    },
    handleBlur() {
      this.focused = false
    },
    focusInput() {
      this.$refs.input.focus()
    },
    getFacets(results) {
      return results.filter(({ uid }) => !uid)
    },
    getTags(results) {
      return results.filter(({ uid }) => uid)
    },
    getResultValue() {
      this.value = this.$refs.input.value
      return ''
    },
    async submitSearchResult(result) {
      let selectedItem = result
      this.store.dispatch('showLoader')
      const addItemToStart = this.$mq != 'desktop'
      const firstFoundItem = this.filteredResults[0]
      // submit the text field and check if found results match with input
      if (!result
        && firstFoundItem && firstFoundItem.name.toLowerCase() == this.value.toLowerCase()) {
        selectedItem = firstFoundItem
        this.$refs.autocomplete.value = ''
        this.$refs.input.blur()
      }
      // it's tag selection
      if (selectedItem && selectedItem.uid) {
        tracking.selectTagFromAutocomplete(selectedItem.name, this.value)
        this.store.dispatch('addTag', { tag: selectedItem, addItemToStart })
        tracking.currentTagCombination()
      } else if (selectedItem && selectedItem.tags) {
        // it's facet selection
        this.store.dispatch('setTags', { tags: selectedItem.tags })
        tracking.selectFacetFromAutocomplete(selectedItem.name, this.value)
      } else {
        // free text search
        this.store.dispatch('updateSearchTerms', { searchTerm: this.$refs.autocomplete.value })
        tracking.freeTextSearch(this.$refs.autocomplete.value)

        this.$refs.autocomplete.value = ''
        this.$refs.input.blur()
      }
      this.store.dispatch('setMobileAutoCompleteVisibility', { value: false })
      pageRefresh.updatePage()
    },
    async removeSearchTerm(searchTerm) {
      tracking.removeTag(searchTerm, 'Top Menu')
      this.store.dispatch('removeSearchTerm', { searchTerm })
      pageRefresh.updatePage()
    },
    async removeTag(tag) {
      tracking.removeTag(tag.name, 'Top Menu')
      this.store.dispatch('removeTag', { tag })
      pageRefresh.updatePage()
    }
  }
}
</script>
